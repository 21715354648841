<template>
  <div class="grid" v-if="isAdmin">
    <div
      class="col-12 lg:col-6 xl:col-4"
      v-for="(value, key) in admin_priti.result_iframe"
      :key="key"
    >
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3"></div>
        <div class="text-900 font-medium text-xl">
          <iframe
            id="iframe"
            :src="value"
            width="100%"
            height="180"
            frameborder="0"
            allowtransparency>
          </iframe>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <iframe
          :src="`${METABASE_URL}/public/question/2561752c-1cf8-48f5-86b1-51bcf5492fb8`"
          frameborder="0"
          width="100%"
          height="600"
          allowtransparency>
        </iframe>
      </div>
    </div>
  </div>

  <div class="grid" v-else>
    <div class="col-12 lg:col-6">
      <div class="card">
        <iframe
          :src="admin_salon.result_iframe.barber_rating"
          frameborder="0"
          width="100%"
          height="445"
          allowtransparency>
        </iframe>
      </div>
    </div>

    <div class="col-12 lg:col-6">
      <div class="grid">
        <div
          class="col-12 lg:col-8"
          v-for="(value, key) in admin_salon.result_iframe"
          :key="key"
          :class="{'p-0': key === 'barber_rating' || key === 'list_transactions'}"
        >
          <div class="card mb-0" v-if="key !== 'barber_rating' && key !== 'list_transactions'">
            <div class="flex justify-content-between mb-3"></div>
            <div class="text-900 font-medium text-xl">
              <iframe
                id="iframe"
                :src="value"
                width="100%"
                height="180"
                frameborder="0"
                allowtransparency>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <iframe
          :src="admin_salon.result_iframe.list_transactions"
          frameborder="0"
          width="100%"
          height="600"
          allowtransparency>
        </iframe>
      </div>
    </div>
  </div>

  <div :user="user"></div>
</template>

<script>
import { useAuthRole } from '@/composables/auth';

export default {
  setup() {
    const { isAdminPriti, getUser } = useAuthRole()
    return { isAdminPriti, getUser }
  },
  props: ['id'],
  data() {
    return {
      METABASE_URL: process.env.VUE_APP_METABASE_URL,
      admin_priti: {
        metabase_data: {
          salon: 4,
          customer: 5,
          barber: 6,
          amount: 8,
          invoice: 7,
          today_registered: 9,
        },
        result_iframe: {
          salon: "",
          customer: "",
          barber: "",
          amount: "",
          invoice: "",
          today_registered: "",
        },
      },
      admin_salon: {
        metabase_data: {
          barber: 1,
          invoice: 3,
          barber_rating: 12,
          list_transactions: 11,
        },
        result_iframe: {
          barber: "",
          invoice: "",
          barber_rating: "",
          list_transactions: "",
        },
      },
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isAdminPriti();
      if (isAdmin) {
        this.getData(isAdmin);
      }
      return isAdmin;
    },
    user() {
      const user = this.getUser();
      if (user && user.salon_id && user.salon_id !== 0) {
        this.getData(false, user.salon_id);
      }
      return user;
    },
  },
  mounted() {},
  methods: {
    getData(isAdminPriti, salon_id = null) {
      const jwt = require("jsonwebtoken");

      const METABASE_SITE_URL = process.env.VUE_APP_METABASE_URL;
      const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY;

      let payload = {}
      let token = ''

      if (isAdminPriti) {
        for (const [key, value] of Object.entries(this.admin_priti.metabase_data)) {
          payload = {
            resource: { question: value },
            params: {},
            exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
          };
          token = jwt.sign(payload, METABASE_SECRET_KEY);
          this.admin_priti.result_iframe[key] = METABASE_SITE_URL + "/embed/question/" + token + "#bordered=false&titled=false";
        }

      } else {
        for (const [key, value] of Object.entries(this.admin_salon.metabase_data)) {
          payload = {
            resource: { question: value },
            params: { salon_id: salon_id },
            exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
          };
          token = jwt.sign(payload, METABASE_SECRET_KEY);
          this.admin_salon.result_iframe[key] = METABASE_SITE_URL + "/embed/question/" + token + `#bordered=false&titled=${key === 'barber_rating' || key === 'list_transactions' ? 'true' : 'false'}`;
        }
      }
    },
  },
};
</script>
